import React, {Component} from "react";
import {Link} from "gatsby";
import logo from "../assets/images/logo-light.png";

class NavThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    //Mobile Menu
    this.mobileMenu();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true
      });
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false
      });
    }
  };

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector(".menu-toggler");
    let mainNav = document.querySelector(".main-navigation");

    mainNavToggler.addEventListener("click", function () {
      mainNav.style.display =
          mainNav.style.display !== "block" ? "block" : "none";
    });
  };

  render() {
    return (
        <header className="site-header site-header__home-three ">
          <div className="topbar-one">
            <div className="container">
              <div className="topbar-one__left">
                <a href="mailto:contacto@oshy.tech">contacto@oshy.tech</a>
              </div>
              <div className="topbar-one__right">
                <a href="/aviso-legal">Aviso legal</a>
                <a href="/cookies">Cookies</a>
                <a href="/privacidad">Privacidad</a>
              </div>
            </div>
          </div>
          <nav
              className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${
                  this.state.sticky ? "stricked-menu stricky-fixed" : ""
              }`}
          >
            <div className="container clearfix">
              <div className="logo-box clearfix">
                <Link to="/" className="navbar-brand">
                  <img
                      src={logo}
                      className="main-logo"
                      width="128"
                      alt="Awesome alter text"
                  />
                </Link>
                <button className="menu-toggler" data-target=".main-navigation">
                  <span className="kipso-icon-menu"></span>
                </button>
              </div>
              <div className="main-navigation">
                <ul className=" navigation-box">
                  <li className={this.props.active === "" ? "current" : ""}>
                    <Link activeClassName={"current"} to="/">Home</Link>
                  </li>
                  <li className={this.props.active === "blog" ? "current" : ""}>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li className={this.props.active === "projects" ? "current" : ""}>
                    <Link to="/proyectos">Proyectos</Link>
                  </li>
                  <li className={this.props.active === "oshy" ? "current" : ""}>
                    <Link to="/oshy">Oshy </Link>
                  </li>
                </ul>
              </div>
              <div className="right-side-box">
                <div className="header__social">
                  <a href="https://twitter.com/OshyTech">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://www.youtube.com/channel/UCAZgvndcorh9hqGg8vg_8Rw?sub_confirmation=1">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a href="https://github.com/oshytech">
                    <i className="fab fa-github"></i>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
    );
  }
}

export default NavThree;
