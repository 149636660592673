import React from "react";
import footer1 from "../assets/images/footer-1-1.png";
import footer2 from "../assets/images/footer-1-2.png";
import footer3 from "../assets/images/footer-1-3.png";
import footer4 from "../assets/images/footer-1-4.png";
import footer5 from "../assets/images/footer-1-5.png";
import footer6 from "../assets/images/footer-1-6.png";
import {Link} from "gatsby";

const Footer = () => {
    function scrollTop() {
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <footer className="site-footer">
                <div className="site-footer__bottom">
                    <div className="container">
                        <p className="site-footer__copy">
                            &copy; Copyright {new Date().getFullYear()} by <Link to={"/"}>OshyTech</Link>
                        </p>
                        <div className="site-footer__social">
                            <div
                                onClick={scrollTop}
                                onKeyDown={scrollTop}
                                role="button"
                                tabIndex={0}
                                className="scroll-to-target site-footer__scroll-top"
                            >
                                <i className="kipso-icon-top-arrow"></i>
                            </div>
                            <a rel={"noreferrer noopener nofollow "} href="https://twitter.com/OshyTech">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a rel={"noreferrer noopener nofollow "} href="https://www.youtube.com/channel/UCAZgvndcorh9hqGg8vg_8Rw?sub_confirmation=1">
                                <i className="fab fa-youtube"></i>
                            </a>
                            <a rel={"noreferrer noopener nofollow "} href="https://github.com/oshytech">
                                <i className="fab fa-github"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};
export default Footer;
