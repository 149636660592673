import React from "react";
import {Helmet} from "react-helmet";
import appleTouch from "../assets/images/favicons/apple-touch-icon.png";
import fav32 from "../assets/images/favicons/favicon-32x32.png";
import fav16 from "../assets/images/favicons/favicon-16x16.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-modal-video/css/modal-video.min.css";
import "../assets/plugins/fontawesome-free-5.11.2-web/css/all.min.css";
import "../assets/plugins/kipso-icons/style.css";
import "../assets/css/animate.min.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import oshyTechXl from "../assets/images/oshytech_xl.webp";

const Layout = ({title, metaDescription, image, keywords, children, index}) => {
    if (image === null) image = oshyTechXl
    keywords = " tecnología desarrollo informatica youtube SEO cursos " + keywords
    let robots = ""
    if (index) robots = "index,follow"
    else robots = "noindex,follow"

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <description>{metaDescription}</description>
                <html lang="es"/>

                <meta name="description" content={metaDescription}/>
                <meta itemProp="description" content={metaDescription}/>
                <meta name="author" content="OshyTech"/>
                <meta name="reply-to" content="contacto@oshy.tech"/>
                <meta name="description" content={metaDescription}/>
                <meta name="keywords" content={keywords}/>

                <meta name="og:title" content={title}/>
                <meta name="og:description" content={metaDescription}/>
                <meta name="og:type" content="website"/>
                <meta property="og:site_name" content="OshyTech"/>
                <meta property="og:image" content={image}/>

                <meta name="twitter:title" content={title}/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:description" content={metaDescription}/>
                <meta name="twitter:creator" content="@OshyTech"/>
                <meta name="twitter:site" content="@OshyTech"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:image" content={image}/>
                <meta name="twitter:image:alt" content="OshyTech"/>

                <link
                    rel="canonical"
                    href={`https://oshy.tech`}
                />

                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta httpEquiv="X-UA-Compatible" content="ie=edge"/>
                <link rel="apple-touch-icon" sizes="180x180" href={appleTouch}/>
                <link rel="icon" type="image/png" sizes="32x32" href={fav32}/>
                <link rel="icon" type="image/png" sizes="16x16" href={fav16}/>

                <link
                    href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,500i,600,700,800%7CSatisfy&display=swap"
                    rel="stylesheet"
                />
            </Helmet>

            <div className="page-wrapper">{children}</div>
        </div>
    );
};

export default Layout;
